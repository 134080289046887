
import { StyleSheet } from 'react-native'

export default StyleSheet.create(
    {
        row: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            padding: 15,
            borderBottomWidth: 1,
            borderBottomColor: '#cccccc',
            backgroundColor: '#ffffff'
        },
        checkbox: {
            width: 24,
            height: 24,
            marginRight: 5
        },
        label: {},

        buttonStyle: {
            height: 40, width: '100%',
            backgroundColor: 'red'
        }
    }
)
